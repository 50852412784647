import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Content from './style'
export default function Overview(){
return(
<Content>
  <Container>
    <Row className="justify-content-center">
      <Col className="col-xxl-12 col-xl-12 col-lg-12">
        <Content.Content>
          
          <Content.ContentBox>
            <Content.TitleSmall as="h3">HubSpot Integration Overview</Content.TitleSmall>
            <Content.Text>The new HubSpot integration streamlines reporting and campaign management with a two-way sync, allowing sales and marketing teams to incorporate LinkedIn automation to their existing activities in Hubspot.  For example, the integration allows you to:</Content.Text>
            <Content.Text>• Track real-time campaign events in HubSpot, such as invite accepted, LinkedIn reply, email open, email clicks, email reply and unsubscribes.</Content.Text>
            <Content.Text>• Sync contact data between We-Connect and HubSpot for a complete view of the buyer journey.</Content.Text>
            <Content.Text>• Import leads from HubSpot into We-Connect to reach contacts with personalized LinkedIn outreach campaigns</Content.Text>
            <Content.Text>• Import contacts from We-Connect into HubSpot, enriching the marketing database.</Content.Text>
            <Content.Text>• Add a contact to a We-Connect automated LinkedIn campaign directly within Hubspot, as well as send or reply to a LinkedIn message, all in one place. <span>(coming soon)</span></Content.Text>
          </Content.ContentBox>
          
        </Content.Content>
      </Col>
    </Row>
  </Container>
</Content>

)
}